import React, { useEffect, useState, useRef } from "react";
import { Link, useParams } from "react-router-dom";
import { Container, Row, Col, ProgressBar, Button, Alert } from "react-bootstrap"; // Add Alert for success message
import MainAppWrap from "../../../layout/MainAppWrap";
import SimpleHeader from "../../SimpleHeader";
import axios from 'axios';
import Recorder from 'recorder-js';
import { useWebSocket } from '../../common/WebsocketContext';
import './css/BrainDump.css';  // Import a custom CSS file for additional styles
import SettingsLoader from "../../common/SettingsLoader";
import LoaderWithText from "../../common/LoaderWithText";
import GkdEditorComp from "./GkdEditorComp";
import { set } from "date-fns";
import { toast, ToastContainer } from "react-toastify";
import { is } from "date-fns/locale";
import lamejs from 'lamejs';


function BrainDump() {
    const { ks_id } = useParams();
    const { sendMessage, addListener, removeListener, webSocketStatus } = useWebSocket();
    const [recorder, setRecorder] = useState(null);
    const [blobURL, setBlobURL] = useState('');
    const [uploadUrl, setUploadUrl] = useState('');
    const [isRecorderReady, setIsRecorderReady] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadSuccess, setUploadSuccess] = useState(false);  // New state for upload success
    const [isRecording, setIsRecording] = useState(false);
    const [recordingDuration, setRecordingDuration] = useState(0); // track recording time
    const buttonRef = useRef(null);
    const animationFrameRef = useRef(null);  // for animation loop
    const [gkdData, setGkdData] = useState({});
    const [isStreaming, setIsStreaming] = useState(false);
    const [isStreamingLoading, setIsStreamingLoading] = useState(false);
    const [gkdJSON, setGkdJSON] = useState({});
    const [gkdURL, setGkdURL] = useState('');
    const [transcriptError, setTranscriptError] = useState("");
    const [showRecordingButton, setShowRecordingButton] = useState(false);
    const [hideActionButtons, setHideActionButtons] = useState(true);

    const [isCompailing, setIsCompailing] = useState(false);

    const [isFileUploading, setIsFileUploading] = useState(false);

    // Initialize recorder on page load
    useEffect(() => {
        if(showRecordingButton) {
            initRecorder();
        }
    }, [showRecordingButton]);

    // Initialize recorder
    const initRecorder = async () => {
        try {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            if (!stream || stream.getAudioTracks().length === 0) {
                throw new Error("No audio tracks available.");
            }
    
            const originalSampleRate = 44100; // Default sample rate for most devices
            const targetSampleRate = 16000; // Desired lower sample rate
    
            const audioContext = new (window.AudioContext || window.webkitAudioContext)({
                sampleRate: targetSampleRate // Set desired sample rate here
            });
    
            const analyserNode = audioContext.createAnalyser();
            const newRecorder = new Recorder(audioContext, { numChannels: 1 });
            await newRecorder.init(stream);
    
            const source = audioContext.createMediaStreamSource(stream);
            source.connect(analyserNode);
    
            // If necessary, handle stream processing for further manipulation
            const processor = audioContext.createScriptProcessor(4096, 1, 1);
            source.connect(processor);
            processor.connect(audioContext.destination);
    
            processor.onaudioprocess = (event) => {
                // Process audio data (optional, if you need to further downsample or modify)
                const inputBuffer = event.inputBuffer;
                const outputBuffer = event.outputBuffer;
    
                // Example: Downsample manually if needed
                // Not necessary if sample rate is already set in AudioContext
            };
    
            setRecorder(newRecorder);
            setIsRecorderReady(true);
        } catch (error) {
            console.error("Error initializing recorder:", error);
        }
    };
    

    const startRecording = () => {
        if (recorder) {
            recorder.start();
            setIsRecording(true);
            handleSendMessage('get_pre_signed_url');
            startVisualization();
        }
    };

    const stopRecording = async () => {
        console.log(recordingDuration);
    
        if(recordingDuration < 30) {
            toast.error("Please record for at least 30 seconds.", {
                position: "bottom-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return;
        }
    
        if (recorder) {
            setIsCompailing(true);
            const { blob } = await recorder.stop();

            // const mp3Blob = await convertToMP3(blob);

            // // Ensure mp3Blob is valid
            // if (!mp3Blob) {
            //     throw new Error("Failed to generate MP3 blob.");
            // }

            const blobURL = URL.createObjectURL(blob);
            setBlobURL(blobURL);
            setIsRecording(false);
            stopVisualization();
            setIsCompailing(false);
            // show the size in MB of the blob
            console.log("Blob size:", (blob.size / 1024 / 1024).toFixed(2), "MB");

            handleUpload(blob);
    
            const tracks = recorder.stream.getTracks();
            tracks.forEach(track => track.stop());

            if (recorder.audioContext) {
                recorder.audioContext.close().then(() => {
                    console.log('Audio context closed');
                }).catch((err) => {
                    console.error('Error closing audio context:', err);
                });
            }

            recorder.stream = null;
            recorder.audioContext = null;
            setIsRecording(false);
        }
    };
    

    const convertToMP3 = async (wavBlob) => {
        try {
            const arrayBuffer = await wavBlob.arrayBuffer();
            if (!arrayBuffer || arrayBuffer.byteLength === 0) {
                throw new Error("Empty audio buffer. No valid audio recorded.");
            }
            const wavHeader = lamejs.WavHeader.readHeader(new DataView(arrayBuffer));
    
            if (!wavHeader || !wavHeader.dataOffset || !wavHeader.dataLen) {
                throw new Error("Invalid WAV header.");
            }
    
            const samples = new Int16Array(arrayBuffer, wavHeader.dataOffset, wavHeader.dataLen / 2);
    
            if (!samples || samples.length === 0) {
                throw new Error("No valid audio samples found.");
            }
    
            console.log("WAV Header:", wavHeader);
            console.log("Sample Length:", samples.length);
    
            // Split into mono channels if necessary
            const leftChannel = [];
            const rightChannel = [];
            for (let i = 0; i < samples.length; i += 2) {
                leftChannel.push(samples[i]);
                rightChannel.push(samples[i + 1]);
            }
    
            const mp3Encoder = new lamejs.Mp3Encoder(2, wavHeader.sampleRate, 64); // Stereo encoding
            const mp3Data = [];
            let offset = 0;
            const sampleBlockSize = 1152;
    
            while (offset < leftChannel.length) {
                const leftChunk = leftChannel.slice(offset, offset + sampleBlockSize);
                const rightChunk = rightChannel.slice(offset, offset + sampleBlockSize);
    
                const mp3Chunk = mp3Encoder.encodeBuffer(leftChunk, rightChunk);
                if (mp3Chunk && mp3Chunk.length > 0) {
                    mp3Data.push(mp3Chunk);
                }
                offset += sampleBlockSize;
            }
    
            const mp3End = mp3Encoder.flush();
            if (mp3End && mp3End.length > 0) {
                mp3Data.push(mp3End);
            }
    
            if (mp3Data.length === 0) {
                throw new Error("Failed to generate MP3 data.");
            }
    
            const mp3Blob = new Blob(mp3Data, { type: "audio/mp3" });
            return mp3Blob;
        } catch (error) {
            console.error("Error converting and compressing to MP3:", error);
            throw error;
        }
    };
    

    const startOverRecording = () => {
        setBlobURL('');
        setRecordingDuration(0);
        setIsRecording(false);
        setUploadSuccess(false); // Reset success message on restart
        startRecording();
    };


    const startVisualization = () => {
        const analyserNode = recorder.audioContext.createAnalyser();
        const source = recorder.audioContext.createMediaStreamSource(recorder.stream);
        source.connect(analyserNode);
        analyserNode.fftSize = 2048;
    
        const bufferLength = analyserNode.fftSize;
        const dataArray = new Uint8Array(bufferLength);
    
        const draw = () => {
            animationFrameRef.current = requestAnimationFrame(draw);
    
            analyserNode.getByteTimeDomainData(dataArray);
    
            const maxVolume = Math.max(...dataArray);
            const shadowSize = (maxVolume / 255) * 30;  // Adjust shadow size based on voice amplitude

            if (maxVolume < 10) { // Arbitrary threshold for silence
                console.warn("Detected very low audio input. Ensure the microphone is active.");
            }
    
            if (buttonRef.current) {
                buttonRef.current.style.boxShadow = `0px 0px ${shadowSize}px ${shadowSize / 4}px rgba(0, 0, 255, 0.9)`;
    
                // Dynamically adjust wave size and speed based on volume
                const waveSize = (maxVolume / 255) * 1.2 + 1;  // Scale the wave size
                const waveSpeed = 2 - (maxVolume / 255);  // Inverse relationship for speed
    
                // Apply dynamic properties for wave effect
                buttonRef.current.style.setProperty('--wave-size', waveSize);
                buttonRef.current.style.setProperty('--wave-speed', `${waveSpeed}s`);
    
                // Add or remove wave effect based on voice amplitude
                if (maxVolume > 50) {
                    buttonRef.current.classList.add('wave-effect');
                } else {
                    buttonRef.current.classList.remove('wave-effect');
                }
            }
        };
    
        draw();
    };

    const stopVisualization = () => {
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
        }
        if (buttonRef.current) {
            buttonRef.current.style.boxShadow = 'none';
        }
    };

    useEffect(() => {
        let timer;
        if (isRecording) {
            timer = setInterval(() => {
                setRecordingDuration((prev) => prev + 1);
            }, 1000);
        } else {
            setRecordingDuration(0);
            clearInterval(timer);
        }
        return () => clearInterval(timer);
    }, [isRecording]);

    const formatTime = (seconds) => {
        const mins = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
    };


const handleUpload = async (blob) => {
    try {
        setIsFileUploading(true);
        await axios.put(uploadUrl, blob, {
            headers: {
                'Content-Type': 'audio/mp3',
            },
            onUploadProgress: (progressEvent) => {
                const percentCompleted = Math.round(
                    (progressEvent.loaded * 100) / progressEvent.total
                );
                setUploadProgress(percentCompleted);
            }
        });
        setUploadSuccess(true); // Set success state on successful upload
        handleSendMessage('get_gkd_data');
        setIsStreaming(true);
        setHideActionButtons(false);
        setIsStreamingLoading(true);
        console.log("File uploaded successfully.");
        setIsFileUploading(false);
    } catch (error) {
        console.error("Failed to upload file:", error);
        setIsFileUploading(false);
        setUploadSuccess(false); // Set to false on failure
    }
};

    useEffect(() => {
        const handleMessage = (message) => {
            let data = JSON.parse(message);
            if (
                data.action === 'gkd_record_braindump' &&
                data.identifier === ks_id + '-' + "braindump"
            ) {
                if (data['pre-signed-url']) {
                    setUploadUrl(data['pre-signed-url']);
                }
            }
            if(data.action === 'gkd_upload_braindump_recording' && data.identifier === ks_id + '-' + "braindump"){
                setIsStreamingLoading(false);
                setGkdData(prevData => ({
                    ...prevData,
                    title: (data.gkd_title !== undefined && data.gkd_title !== null)  ? (prevData.title ? prevData.title + data.gkd_title : data.gkd_title) : prevData.title,
                    text: data.gkd_text ? (
                        prevData.text
                            ? prevData.text + data.gkd_text.replace(/^```markdown\s*/, '').replace(/\s*```$/, '')
                            : data.gkd_text.replace(/^```markdown\s*/, '').replace(/\s*```$/, '')
                    ) : prevData.text,


                    questions: data.gkd_questions ? (prevData.questions ? [...prevData.questions, ...data.gkd_questions] : data.gkd_questions) : prevData.questions,
                    references: data.gkd_references ? (prevData.references ? [...prevData.references, ...data.gkd_references] : data.gkd_references) : prevData.references,
                }));
            }
            if(data.action === 'gkd_upload_braindump_recording' && data.identifier === ks_id + '-' + "braindump"){
                 if(data.gkd_url){
                    setGkdURL(data.gkd_url);
                 }
                 if(data.gkd_json){
                    setGkdJSON(data.gkd_json);
                    setGkdData(data.gkd_json);
                 }
            }

            if(data.error === 'transcription failed') {
                setTranscriptError(data.error);
            }
        };
        addListener(handleMessage);

        return () => {
            removeListener(handleMessage);
        };
    }, [addListener, removeListener]);

    const handleSendMessage = (type) => {
        let typeS = type;
        let message = {};

        if (webSocketStatus.current === 'open') {
            if (typeS === 'get_pre_signed_url') {
                 message = {
                    action: 'gkd_record_braindump',
                    ks_id: ks_id,
                    identifier: ks_id + '-' + "braindump",
                    file_type: 'mp3'
                };
            }
            if (typeS === 'get_gkd_data'){
                 message = {
                    action: 'gkd_upload_braindump_recording',
                    ks_id: ks_id,
                    identifier: ks_id + '-' + "braindump",
                    url: uploadUrl,
                }
            }
            sendMessage(JSON.stringify(message));
        } else {
            setTimeout(() => {
                handleSendMessage(typeS);
            }, 5000);
        }
    };

    return (
        <MainAppWrap>
            <div className="main-content">
                <SimpleHeader title="New Guided Knowledge Document" />
                <Container fluid="xxl">
                    {!isStreaming && showRecordingButton &&  (
                    <div className="brain-dump-page">
                    {!blobURL && !isCompailing && (
                        <Row>
                            <Col>
                                <p>Press record below and speak for 1-2 minutes about a specific topic to teach the AI about the topic. Don’t worry too much about organization but speak clearly and cover the important facts and concepts.</p>
                            </Col>
                        </Row>
                    )}
                        {!blobURL && !isCompailing && (
                        <Row>
                            <Col className="recorder-content">
                                <div className="controls">
                                {isRecorderReady ? (
                                <p className="recorder-status ready">Recorder</p>
                            ) : (
                                <p className="recorder-status ready">Initializing recorder...</p>
                            )}
                                    <p className="timer">{formatTime(recordingDuration)}</p>
                                    {!isRecording && (
                                        <button className="play-button" onClick={startRecording}>
                                            {/* Play button with CSS icon */}
                                        </button>
                                    )}
                                    {isRecording && (
                                        <button
                                            ref={buttonRef}
                                            className="round-stop-button"
                                            onClick={stopRecording}
                                        >
                                            {/* Stop button with CSS icon */}
                                        </button>
                                    )}
                                </div>
                            </Col>
                        </Row>
                        )}
                        {isCompailing && (
                            <Row>
                                <Col className="recorder-content">
                                    <LoaderWithText text="Compiling audio..." />
                                </Col>
                            </Row>
                        )}
                        {isFileUploading && (
                            <Row>
                                <Col className="recorder-content">
                                    {uploadSuccess && (
                                        <Alert variant="success">
                                            File uploaded successfully!
                                        </Alert>
                                    )}

                                    <div className="file-upload-percentage">
                                    <LoaderWithText text="Uploading..." />
                                    </div>
                                    {/* <audio controls src={blobURL} className="audio-player" />
                                    <Button variant="success" onClick={handleUpload}>
                                        Upload Recording
                                    </Button>
                                    <Button variant="secondary" onClick={startOverRecording}>
                                        Start Over
                                    </Button> */}
                                    <ProgressBar
                                        now={uploadProgress}
                                        label={`${uploadProgress}%`}
                                        className="upload-progress"
                                    />
                                </Col>
                            </Row>
                        )}
                    </div>
                    )}
                        <GkdEditorComp
                            gkdData={gkdData}
                            setGkdData={setGkdData}
                            ks_id={ks_id}
                            filename="braindump"
                            isLoadingPage={false}
                            brainDump={true}
                            gkdJSON={gkdJSON}
                            gkdURL={gkdURL}
                            isStreamingLoading={isStreamingLoading}
                            isBrainDump={true}
                            setShowRecordingButton={setShowRecordingButton}
                            showRecordingButton={showRecordingButton}
                            hideActionButtons={hideActionButtons}
                            setHideActionButtons={setHideActionButtons}
                        />
                    {transcriptError && transcriptError !== '' &&(
                        <Alert variant="danger">
                            Transcript failed. Please try again.
                            <Link to={`/settings/ks/${ks_id}/new-gkd`}>Try again</Link>
                        </Alert>
                        )}
                </Container>
            </div>
            <ToastContainer />
        </MainAppWrap>
    );
}

export default BrainDump;