import React, { useState, useEffect, useRef } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { Grid, html, h } from "gridjs";
import "gridjs/dist/theme/mermaid.css";
import { apiPath } from "../../config";
import { useAuth0 } from "@auth0/auth0-react";
import ResponseListFilters from "./partials/RsponseListFilters";
import { ReactComponent as FilterIcon } from "../../images/v2/responseList/filter.svg";
import { ReactComponent as FilterIconActive } from "../../images/v2/responseList/filter_active.svg";
import ReactDOMServer from "react-dom/server";
import {
  convertDateIntoLocalDate,
  convertTimeStampToUnix,
} from "../common/Functions";
import { Modal, Button } from "react-bootstrap";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // Main style
import "react-date-range/dist/theme/default.css"; // Theme css
import SettingLoader from "../common/SettingsLoader";
import { format, set } from "date-fns";

function ResponseListGrid() {
  const [searchParams, setSearchParams] = useSearchParams();
  const { user, getIdTokenClaims } = useAuth0();
  const [columnVisibility, setColumnVisibility] = useState(Array(6).fill(true));
  const [channelList, setChannelList] = useState([]);
  const [channelIds, setChannelIds] = useState([]);

  const gridRef = useRef(null);

  const [showChannelModal, setShowChannelModal] = useState(false);
  const [showDateModal, setShowDateModal] = useState(false);
  const [selectedRange, setSelectedRange] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection",
    },
  ]);
  const tags = [
    { id: 0, name: "All", value: "all" },
    { id: 1, name: "Resolved", value: "resolved" },
    { id: 2, name: "For Review", value: "for_review" },
  ];
  const likes_dislikes_comments = [
    { id: 0, name: "All", value: "all" },
    { id: 1, name: "Likes", value: "likes" },
    { id: 2, name: "Dislikes", value: "dislikes" },
    { id: 3, name: "Comments", value: "comments" },
  ];
  const getInitialValue = (key, defaultValue) => {
    return searchParams.get(key) || defaultValue;
  };
  
  const [startDate, setStartDate] = useState(getInitialValue("startDate", null));
const [endDate, setEndDate] = useState(getInitialValue("endDate", null));
const [selectedChannels, setSelectedChannels] = useState(
  JSON.parse(getInitialValue("selectedChannels", "[]"))
);
const [selectedTag, setSelectedTag] = useState(getInitialValue("selectedTag", "all"));
const [selectedLikesDislikesComments, setSelectedLikesDislikesComments] = useState(
  getInitialValue("selectedLikesDislikesComments", "all")
);


  const [activeModal, setActiveModal] = useState(null); // Track the currently active modal

const handleModalToggle = (modalName) => {
  setActiveModal((prev) => (prev === modalName ? null : modalName)); // Toggle modal state
};

const updateSearchParams = (keyOrUpdates, value) => {
  const params = new URLSearchParams(searchParams);

  if (typeof keyOrUpdates === "string") {
    // Single key-value update
    if (value !== null && value !== undefined) {
      params.set(keyOrUpdates, String(value)); // Convert value to string explicitly
    } else {
      params.delete(keyOrUpdates);
    }
  } else if (typeof keyOrUpdates === "object") {
    // Bulk updates (key-value pairs)
    Object.entries(keyOrUpdates).forEach(([key, val]) => {
      if (val !== null && val !== undefined) {
        params.set(key, String(val)); // Convert value to string explicitly
      } else {
        params.delete(key);
      }
    });
  }

  setSearchParams(params);
};




  const handleDateChange = (ranges) => {
    // Update the selected range with the new start and end dates
    setSelectedRange([ranges.selection]);

    let startDate = new Date(ranges.selection.startDate);
    let endDate = new Date(ranges.selection.endDate);

    if (startDate.getTime() === endDate.getTime()) {
      // Add 23 hours and 59 minutes (23 hours * 60 minutes/hour + 59 minutes * 60 seconds/minute * 1000 milliseconds/second)
      endDate = new Date(endDate.getTime() + ((23 * 60 + 59) * 60 + 59) * 1000);
      console.log("Adjusted End Date:", endDate); // Log adjusted end date for debugging
    }

    // Update the start and end date states for filtering
    setStartDate(convertTimeStampToUnix(startDate));
    setEndDate(convertTimeStampToUnix(endDate));
    // Batch update both startDate and endDate in the URL
      updateSearchParams({
        startDate: convertTimeStampToUnix(startDate),
        endDate: convertTimeStampToUnix(endDate),
      });

  };

  const handleTagChange = (tagValue) => {
    setSelectedTag(tagValue);
    updateSearchParams("selectedTag", tagValue);
  };
  
  const handleLikesDislikesCommentsChange = (value) => {
    setSelectedLikesDislikesComments(value);
    console.log("Selected Likes, Dislikes, Comments:", value);
    updateSearchParams("selectedLikesDislikesComments", value);
  };
  
  const handleChannelChange = (channels) => {
    setSelectedChannels(channels);
    updateSearchParams("selectedChannels", JSON.stringify(channels));
  };

  const getElementPosition = (element) => {
    const rect = element.getBoundingClientRect();
    return {
      top: rect.top + window.scrollY,
      left: rect.left + window.scrollX,
    };
  };

  useEffect(() => {
    const handleClickOutside = (e) => {
      // Close modals when clicking outside
      if (
        activeModal &&
        !document
          .getElementById(`${activeModal}-container`)
          ?.contains(e.target) &&
        e.target.id !== `${activeModal}-button`
      ) {
        setActiveModal(null); // Close the active modal
      }
    };
  
    document.addEventListener("click", handleClickOutside);
  
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [activeModal]);
  
  // Position the modals dynamically when active
  useEffect(() => {
    if (activeModal) {
      const button = document.getElementById(`${activeModal}-button`);
      const container = document.getElementById(`${activeModal}-container`);
      const buttonPosition = getElementPosition(button);
      if (container) {
        container.style.top = `${buttonPosition.top + 40}px`;
        container.style.left =
          activeModal === "date"
            ? `${buttonPosition.left - 400}px`
            : `${buttonPosition.left - 100}px`;
      }
    }
  }, [activeModal]);

  const handleApplyDateFilter = () => {
    setShowDateModal(false);
    console.log("Date Range Applied:", startDate, endDate);
  };

  const fetchChannels = async () => {
    try {
      const tokenClaims = await getIdTokenClaims();
      const idToken = tokenClaims.__raw;

      const response = await fetch(`${apiPath()}/channel_list`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const result = await response.json();
      setChannelList(result);
    } catch (error) {
      console.error("Error fetching channels:", error);
    }
  };

  const columnMap = [
    {
      id: "question",
      name: "Question",
      sortable: true,
      formatter: (cell, row) => {
        const linkHref = `/response/${encodeURIComponent(
          cell.response_id
        )}`;
        const text = cell.text;

        return html(`
                <div style="max-width: 300px; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">
                    <a 
                        class="response-question-link" 
                        href="${linkHref}" 
                        title="${text}">
                        ${text}
                    </a>
                </div>
            `);
      },
    },
    // { id: "text", name: "Response", data: (row) => html(row.text) },
    {
      id: "channel_name",
      name: html(
        ReactDOMServer.renderToString(
          <div className="gridjs-nowrap">
            Channel
            <button
              id="channel-button"
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                marginLeft: "8px",
              }}
            >

            {selectedChannels.length > 0 ? (
              <FilterIconActive />
             ):(
              <FilterIcon />
             ) }
            </button>
          </div>
        )
      ),
    },
    {
      id: "date",
      name: html(
        ReactDOMServer.renderToString(
          <div className="gridjs-nowrap">
            Date
            <button
              id="date-button"
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                marginLeft: "8px",
              }}
            >
            {startDate !== null || endDate !== null ? (
              <FilterIconActive />
              ) : (
              <FilterIcon />
              )}
            </button>
          </div>
        )
      ),
    },
    {
      id: "likes_and_dislikes",
      name: html(
        ReactDOMServer.renderToString(
          <div className="gridjs-nowrap" style={{minWidth:230}}>
            Likes, Dislikes & Comments
            <button
              id="likes_dislikes_comments-button"
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                marginLeft: "8px",
              }}
            >
            {selectedLikesDislikesComments !== 'all' ? (
              <FilterIconActive />
              ) : (
              <FilterIcon />
              )}
            </button>
          </div>
        )
      ),
      formatter: (cell, row) => {
        return html(`
                <div style="display: flex; align-items: center; gap: 10px;">
                    <span style="display: flex; align-items: center;">
                        <span class="responseListLike"></span>
                        ${cell.likes}
                    </span>
                    <span style="display: flex; align-items: center;">
                        <span class="responseListDislike"></span>
                        ${cell.dislikes}
                    </span>
                    <span style="display: flex; align-items: center;">
                        <span class="responseListComment"></span>
                        ${cell.comments || 0}
                    </span>
                </div>
            `);
      },
    },
    {
      id: "tags",
      name: html(
        ReactDOMServer.renderToString(
          <div>
            Tags
            <button
              id="tags-button"
              style={{
                background: "none",
                border: "none",
                cursor: "pointer",
                marginLeft: "8px",
              }}
            >
            {selectedTag !== 'all' ? (
              <FilterIconActive />
            ):(
              <FilterIcon />
            )}
            </button>
          </div>
        )
      ),
      formatter: (cell, row) => {
        // Access tags from the data cell
        const tagsString = cell;
      
        // Parse tagsString to JSON
        let tags = [];
        try {
          tags = JSON.parse(tagsString); // Convert stringified JSON to array
        } catch (error) {
          console.error("Failed to parse tags:", error, "TagsString:", tagsString);
          return "Invalid tags"; // Return default error message
        }
      
        // Filter for the "action" tag
        const actionTag = tags.filter((tag) => tag.tag_type === "action");
        const feedbackTag = tags.filter((tag) => tag.tag_type === "feedback");
      
        // Render the HTML
        return html(`
          <div style="display: flex; gap: 10px;">
              ${actionTag.length > 0 ? actionTag.map(tag => `${tag.tag_value === "Investigate" ? '<span class="tabletag for_review">For Review</span>': ''}`).join("") : ""}
              ${feedbackTag.length > 0 ? feedbackTag.map(tag => `${tag.feedback_value === true ? '<span class="tabletag resolved">Resolved</span>': ''}`).join("") : ""}
          </div>
        `);
      }
            
    },
  ];

  const initializeGrid = async () => {
    if (!user) return;

    try {
      const tokenClaims = await getIdTokenClaims();
      const idToken = tokenClaims.__raw;

      const visibleColumns = columnMap.filter(
        (_, index) => columnVisibility[index]
      );

      // Destroy existing Grid.js instance if it exists
      if (gridRef.current) {
        gridRef.current.destroy();
        gridRef.current = null; // Reset the grid reference
      }

      const grid = new Grid({
        columns: visibleColumns,
        pagination: {
          limit: 10,
          server: {
            url: (prev, page, limit) => {
              const url = new URL(prev, window.location.origin);
              url.searchParams.set("resultsPerPage", limit);
              url.searchParams.set("page", page + 1);
              url.searchParams.set("format", "gridjs");
              if (startDate) url.searchParams.set("start_timestamp", startDate);
              if (endDate) url.searchParams.set("end_timestamp", endDate);
              if (selectedChannels.length > 0) {
                const channelIdsArray = JSON.stringify(selectedChannels);
                url.searchParams.set("channel_ids", channelIdsArray);
              }
              
              let tags = {};

              if (selectedLikesDislikesComments === "likes") {
                tags.feedback_type = "like";
              } else if (selectedLikesDislikesComments === "dislikes") {
                tags.feedback_type = "dislike";
              } else if (selectedLikesDislikesComments === "comments") {
                tags.feedback_type = "comment";
              }

              if (selectedTag === "for_review") {
                tags.filters = [
                  { tag_type: "action" },
                  { tag_value: "Investigate" },
                ];
                tags.logic = "AND";
              } else if (selectedTag === "resolved") {
                tags.filters = [
                  { feedback_type: "Resolved" },
                  { tag_value: "True" },
                ];
                tags.logic = "AND";
              }

              if (tags.filters || tags.feedback_type) {
                url.searchParams.set("tags", JSON.stringify(tags));
              }

              return url.toString();

            },
          },
        },
        search: {
          server: {
            url: (prev, keyword) => {
              const url = new URL(prev, window.location.origin);
              url.searchParams.set("query", keyword);
              return url.toString();
            },
          },
          placeholder: "Search question…"
        },
        server: {
          url: `${apiPath()}/response_list`,
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
          then: (data) =>
            data.results.map((response) => ({
              question: {
                response_id: response.response_id,
                text: response.question,
              },
              text: response.text,
              channel_name: response.channel_name,
              date: convertDateIntoLocalDate(response.date),
              likes_and_dislikes: {
                likes: response.likes,
                dislikes: response.dislikes,
                comments: response.comments,
              },
              tags: response.tags,
            })),
          total: (data) => data.count,
        },
      });

      grid.render(document.getElementById("grid-wrapper"));
      gridRef.current = grid;
      setTimeout(() => {
        const searchInput = document.querySelector(".gridjs-search .gridjs-input");
        if (searchInput) {
          searchInput.placeholder = "Search question…"; // Set your custom placeholder text here
        }
      }, 0);
      setTimeout(() => {
        const channelButton = document.getElementById("channel-button");
        const dateButton = document.getElementById("date-button");
        const tagsButton = document.getElementById("tags-button");
        const likesButton = document.getElementById("likes_dislikes_comments-button");
      
        channelButton.addEventListener("click", () => handleModalToggle("channel"));
        dateButton.addEventListener("click", () => handleModalToggle("date"));
        tagsButton.addEventListener("click", () => handleModalToggle("tags"));
        likesButton.addEventListener("click", () => handleModalToggle("likes_dislikes_comments"));
      }, 100);
      
    } catch (error) {
      console.error("Error initializing grid:", error);
    }
  };

  const handleResetFilters = () => {
    setStartDate(null);
    setEndDate(null);
    setSelectedChannels([]);
    setSelectedTag("all");
    setSelectedLikesDislikesComments("all");
    updateSearchParams({
      startDate: null,
      endDate: null,
      selectedChannels: [],
      selectedTag: "all",
      selectedLikesDislikesComments: "all",
    });
  };

  useEffect(() => {
    if (user) fetchChannels();
  }, [user]);

  useEffect(() => {
    if (user) initializeGrid();
  }, [
    user,
    columnVisibility,
    startDate,
    endDate,
    selectedChannels,
    selectedTag,
    selectedLikesDislikesComments,
  ]);


  const customTheme = {
    background: "#4F46E5",
    textColor: "#FFFFFF",
    selectionColor: "#FFFFFF",
    todayColor: "#FFFFFF",
    weekdayColor: "#FFFFFF",
  };

  if (!user) {
    return (
      <div>
        <SettingLoader />
      </div>
    );
  }

  return (
    <div>
      <ResponseListFilters
        columnVisibility={columnVisibility}
        setColumnVisibility={setColumnVisibility}
        options={[
          {
            id: "0",
            name: "Question",
            value: "question",
          },
          {
            id: "1",
            name: "Channel",
            value: "channel",
          },
          {
            id: "2",
            name: "Date",
            value: "date",
          },
          {
            id: "3",
            name: "Likes, Dislikes & Comments",
            value: "likes_and_dislikes",
          },
          {
            id: "4",
            name: "Tags",
            value: "tags",
          },
        ]}
        handleResetFilters={handleResetFilters}
      />
      <div id="grid-wrapper"></div>
      {activeModal === "channel" && (
        <div
          id="channel-container"
          className="custom-modals multi-select-modal"
        >
          {channelList.map((channel) => (
            <div key={channel.channel} className="custom-checkbox-for-filter">
              <input
                type="checkbox"
                id={`channel-${channel.channel}`}
                value={channel.channel}
                checked={selectedChannels.includes(channel.channel)}
                onChange={() => {
                  const updatedChannels = selectedChannels.includes(channel.channel)
                    ? selectedChannels.filter((id) => id !== channel.channel)
                    : [...selectedChannels, channel.channel];
                  handleChannelChange(updatedChannels);
                }
                }
              />
              <label htmlFor={`channel-${channel.channel}`}>
                {channel.name}
              </label>
            </div>
          ))}
        </div>
      )}

      {activeModal === "date" && (
        <div id="date-container" className="custom-modals date-modal">
          <DateRangePicker
            ranges={selectedRange}
            onChange={handleDateChange}
            moveRangeOnFirstSelection={false}
            color="#4F46E5" // Primary color for selected ranges
            rangeColors={["#053F98", "#6C63FF"]} // Alternate range colors
            theme={{
              background: customTheme.background,
              textColor: customTheme.textColor,
              selectionColor: customTheme.selectionColor,
              todayColor: customTheme.todayColor,
              weekdayColor: customTheme.weekdayColor,
            }}
          />
        </div>
      )}

      {activeModal === "likes_dislikes_comments" && (
        <div id="likes_dislikes_comments-container" className="custom-modals multi-select-modal">
          {likes_dislikes_comments.map((like_dislike_comment) => (
            <div key={like_dislike_comment.id} className="custom-radio-for-filter">
              <input
                type="radio" // Change to radio for single select behavior
                name="likes_dislikes_comments" // Ensure all inputs belong to the same group
                id={`likes_dislikes_comments-${like_dislike_comment.id}`}
                value={like_dislike_comment.value}
                checked={selectedLikesDislikesComments === like_dislike_comment.value} // Adjust for single value
                onChange={() => handleLikesDislikesCommentsChange(like_dislike_comment.value)} // Update selected tag
              />
              <label htmlFor={`likes_dislikes_comments-${like_dislike_comment.id}`}>{like_dislike_comment.name}</label>
            </div>
          ))}
        </div>
      )}

      {activeModal === "tags" && (
        <div id="tags-container" className="custom-modals multi-select-modal">
          {tags.map((tag) => (
            <div key={tag.id} className="custom-radio-for-filter">
              <input
                type="radio" // Change to radio for single select behavior
                name="tags" // Ensure all inputs belong to the same group
                id={`tags-${tag.id}`}
                value={tag.value}
                checked={selectedTag === tag.value} // Adjust for single value
                onChange={() => handleTagChange(tag.value)  } // Update selected tag
              />
              <label htmlFor={`tags-${tag.id}`}>{tag.name}</label>
            </div>
          ))}
        </div>
      )}

    </div>
  );
}

export default ResponseListGrid;
