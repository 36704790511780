import React, {useState} from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import "./css/AddKnowledgePopUp.css";

function AddKnowledgePopUp({knowledgeSourceOptions, handleAddKnowledge}) {
    const [selectedKnowledgeSource, setSelectedKnowledgeSource] = useState(null);
    const [selectedKnowledgeSourceID, setSelectedKnowledgeSourceID] = useState(null);
    const handleKnowledgeSourceChange = (selectedOption) => {
        console.log("Selected Knowledge Source: ", selectedOption);
        setSelectedKnowledgeSource(selectedOption);
        setSelectedKnowledgeSourceID(selectedOption.value);
    };
    const handleAdd = (e) => {
        if (!selectedKnowledgeSource) {
            e.preventDefault();
        }
    }

  return (
        <div className="overlay-popup">
            <div className="overlaypopup-bg"></div>
            <div className="overlaypopup-box">
                <div className="overlaypopup-header">
                    <h4>Select Knowledge Source</h4>
                </div>
                <div className="overlaypopup-body">
                <Select
                    options={knowledgeSourceOptions.map((ks) => ({
                        value: ks.ks_id,
                        label: ks.name + " (" + ks.ks_id + ")",
                    }))
                    }
                    onChange={handleKnowledgeSourceChange}
                    placeholder="Select Knowledge Source"
                    isSearchable={false}
                    value={selectedKnowledgeSource}
                />
                </div>
                <div className="overlaypopup-footer">
                    <button className="btn btn-secondary" onClick={handleAddKnowledge}>Cancel</button>
                    <Link to={`/settings/ks/${selectedKnowledgeSourceID}/new-gkd`} onClick={handleAdd} className="btn btn-primary">Add</Link>
                </div>

            </div>
                
        </div>
    );
}

export default AddKnowledgePopUp;