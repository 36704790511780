import React, { useState, useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Container, Row, Col } from "react-bootstrap";
import MainAppWrap from "../../layout/MainAppWrap";
import SimpleHeader from "../SimpleHeader";
import { Link } from "react-router-dom";
import DOMPurify from "dompurify";
import ept_bot_img from "../../images/ept_bot.svg";
import FeedbackAndCopy from "../askquestion/FeedbackAndCopy";
import Sources from "./partials/Sources";
import SettingsLoader from "../common/SettingsLoader";
import {convertDateIntoLocalDate} from '../common/Functions';
import { post } from "jquery";
import { apiPath } from "../../config";
import {marked}  from "marked";
import AnswerFeedback from "./partials/AnswerFeedback";
import BreadCrumbs from "../BreadCrumbs";
import ResponseDetailsHeader from "./partials/ResponseDetailsHeader";
import ResponseComments from "./partials/ResponseComments";
import SourcesGrid from "./partials/SourcesGrid";
import AddKnowledgePopUp from "./partials/AddKnowledgePopUp";

function ResponseDetails(props) {
  const navigate = useNavigate();
  let { response_id } = useParams();
  response_id = encodeURIComponent(response_id); 
  const [searchParams, setSearchParams] = useSearchParams();
  const { getIdTokenClaims, user } = useAuth0();
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [currentRanking, setCurrentRanking] = useState([]);
  const [responseLikes, setResponseLikes] = useState(0);
  const [responseDislikes, setResponseDislikes] = useState(0);
  const [rating, setRating] = useState(searchParams.get("rating") || null);
  const [showAddKnowledge, setShowAddKnowledge] = useState(false);

  const getResponse = async () => { 
    setIsLoading(true); 
    try {
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      //   console.log('idToken-', idToken);

      const options = {
        method: "GET",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      };
      const thePath = `/response?response_id=${response_id}`;

      const url = `${apiPath()}${thePath}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      if(result.response_id === null){
        setErrorMessage("Response not found");
      }
      // console.log("result-", result);
      setIsLoading(false);
      setResponse(result);
      // console.log("result-", result);
    } catch (error) {
        setIsLoading(false);
        setErrorMessage("You do not have permission to access to this response.");
      console.error("Error fetching data:", error);
    }
  };

  const fetchRanking = async () => {
    try {
      // Get the ID token from Auth0
      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      //   console.log('idToken-', idToken);

      const options = {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${idToken}`,
          'Content-Type': 'application/json'
        },
      };
      const rankPath = `/response/ranking?response_id=${response_id}`;

      const url = `${apiPath()}${rankPath}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const rankResult = await response.json();

      // console.log("ranking-", rankResult);
      setCurrentRanking(rankResult)
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (user && response_id) {
      getResponse();
      fetchRanking();
    }
  }, [user, response_id]);

  useEffect(() => {
    // console.log(response)
    if(response.response_id !== undefined && response.response_id !== "" ){
      if (rating) {
        if (rating === "like") {
          sendFeedback(response_id, true);
        } else {
          sendFeedback(response_id, false);
        }
      }
    }
  }, [rating, response]);


  const cleanHtmlContent = (html, output_format) => {
    let cleanhtml = '';
    if(output_format === 'markdown'){
      cleanhtml = marked(html);
    }else{
      cleanhtml = html;
    }

    let sanitizedHtml = DOMPurify.sanitize(cleanhtml, { USE_PROFILES: { html: true } });

    // Enhanced to remove language specifier along with code block delimiters
    // This will remove instances like ```html or ```javascript along with the triple backticks
    sanitizedHtml = sanitizedHtml.replace(/```(\w+)?\s?/g, "");

    // Find anchor links and add target="_blank" attribute
    sanitizedHtml = sanitizedHtml.replace(
      /<a\s+(?:[^>]*?\s+)?href="([^"]*)"([^>]*?)>/gi,
      (match, href, rest) => {
        // Check if the link already has a target attribute
        if (!rest.includes("target=")) {
          return `<a href="${href}" target="_blank"${rest}>`;
        } else {
          return match; // Link already has a target attribute
        }
      }
    );

    return sanitizedHtml;
  };

  const sendFeedback = async (response_id, feedback, itemurl, resolve) => {
    try {
      let postData = {};
      let text = "";
      if(currentRanking){
        if(currentRanking.text){
          text = currentRanking.text;
        }
      }
      if (feedback) {
        postData = {
          response_id: response_id,
          ratings: {
            thumbs_up: Number(1),
            text:text,
          },
        };
      } else {
        postData = {
          response_id: response_id,
          ratings: {
            thumbs_down: Number(1),
            text:text,
          },
        };
      }
      if (itemurl) {
        postData = {
          response_id: response_id,
          url: itemurl,
          likes: feedback ? 1 : 0,
          dislikes: feedback ? 0 : 1,
        };
      }
      if (resolve) {
        postData = {
          response_id: response_id,
          resolve: true,
        };
      }
      postData = JSON.stringify(postData);
      // console.log("postData-", postData);

      const getidToken = await getIdTokenClaims();
      const idToken = getidToken.__raw;
      const options = {
        method: "POST",
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
        body: postData,
      };

      const rankPath = `/response/ranking`;
      const url = `${apiPath()}${rankPath}`;
      const response = await fetch(url, options);
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const result = await response.json();
      if(!itemurl){
        setCurrentRanking(result);
      }
      // console.log("result-", result);
      return result;
    } catch (error) {
      console.error("Error fetching data:", error);
      return { error: error };
    }
  };

  const handleResolve = async (e) => {
    e.preventDefault();
    console.log('resolve click')
    const sendResolve = await sendFeedback(response_id, null, null, true);
    if (sendResolve) {
      navigate(`/responses`);
    }
  };

  const handleAddKnowledge = async (e) => {
    e.preventDefault();
    setShowAddKnowledge(!showAddKnowledge);
  };

  if(errorMessage !== "" && !isLoading){
    return (
      <MainAppWrap>
        <div className="main-content response-detail-page">
          <SimpleHeader />
          <Container fluid="xxl">
            <Row>
              <Col>
                <h3 className="main-error-message">{errorMessage}</h3>
              </Col>
            </Row>
          </Container>
        </div>
      </MainAppWrap>
    );
  }

  return (
    <MainAppWrap>
      <div className="main-content response-detail-page">
        {/* <SimpleHeader /> */}
        <BreadCrumbs crumbs={[
          {
            label: 'Responses',
            url: '/responses'
          },
          {
            label: 'Responses Details Page',
          }
        ]} />
        {!isLoading && (
          <Container fluid="xxl">
            <Row>
              <Col>
                <ResponseDetailsHeader response={response} />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <div className="chat-window-wrap">
                  <div
                    className="chat-messages-wrap height-one-line"
                    id="chat-messages-wrap-id"
                  >
                    <ul id="eptai-chat-messages">
                        <li className="user-message bot-message">
                        <div className="chat-user-image">
                            <span className="user-placeholder-image"></span>
                        </div>
                        <div className="chat-message-wrap">
                            {/* <div className="message-sender">User</div> */}
                            <div dangerouslySetInnerHTML={{__html: cleanHtmlContent(response.question, response.output_format)}}></div>
                        </div>
                        </li>
                      <li className="bot-message">
                        <div className="chat-user-image">
                          <img
                            className="profile-avatar"
                            src={ept_bot_img}
                            alt="EPTAI"
                          />
                        </div>
                        <div className="chat-message-wrap">
                          <div className="message-sender">ept AI</div>
                          <div
                            id={response.response_id}
                            dangerouslySetInnerHTML={{
                              __html: cleanHtmlContent(response.response_text, response.output_format),
                            }}
                          ></div>

                          <FeedbackAndCopy
                            response_id={response.response_id}
                            sendFeedback={sendFeedback}
                            currentRanking={currentRanking}
                          />
                        </div>
                      </li>
                    </ul>
                    <div className="response-details-extra-buttons">
                    {response.tags
                        .filter((tag) => tag.tag_type === 'action')
                        .map((tag, index) =>
                        tag.tag_value === 'Investigate' ? (
                            <div key={`action-${index}`}>
                            <a href="#" className="chat-feedback-resolve-btn new-btn" onClick={handleResolve}>Resolve</a>
                            </div>
                        ) : null
                        )}
                        {response && response.contributed_knowledge_ks_ids.length > 0 && (
                        <>
                          {response.contributed_knowledge_ks_ids.length === 1 ? (
                            <Link 
                              to={`/settings/ks/${response.contributed_knowledge_ks_ids[0].ks_id}/new-gkd`} 
                              className="chat-feedback-create-btn new-btn"
                            >
                              Add Knowledge
                            </Link>
                          ) : (
                            <>
                              <a 
                                href="#" 
                                className="chat-feedback-add-btn new-add-btn" 
                                onClick={handleAddKnowledge}
                              >
                                Add Knowledge
                              </a>
                              {showAddKnowledge && (
                                <AddKnowledgePopUp 
                                  knowledgeSourceOptions={response.contributed_knowledge_ks_ids} 
                                  handleAddKnowledge={handleAddKnowledge} 
                                />
                              )}
                            </>
                          )}
                        </>
                      )}

                      {response && response.can_create_contributed_knowledge_ks && response.contributed_knowledge_ks_ids.length <= 0 && (
                        <Link to={`/settings/ks/create?create_ks_type=ck`} className="chat-feedback-create-btn new-btn">Create Contributed Knowledge</Link>
                      )}
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <ResponseComments 
                  comments={response.comments}
                  response_id={response.response_id}
                  currentRanking={currentRanking}
                  setResponse={setResponse}
                  response={response}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <SourcesGrid
                  response_id={response.response_id}
                  sources={response.sources}
                  sendFeedback={sendFeedback}
                />
              </Col>
            </Row>
          </Container>
        )}
        {isLoading && (
            <Container fluid="xxl">
                <Row>
                    <Col>
                    <SettingsLoader />
                    </Col>
                </Row>
            </Container>
        )}
      </div>
    </MainAppWrap>
  );
}

export default ResponseDetails;
